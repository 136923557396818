<template>
    <div class="body-container">
        <BannerSection
                       title="Consigue dinero por tu carro usado en <br class='d-none d-lg-block'/>México sin tener que venderlo" />
        <PerksComponent></PerksComponent>

        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <p class="mb-0">
                        ¿Necesitas liquidez financiera pero no quieres desprenderte de tu coche? En Ibancar, te
                        ofrecemos la solución perfecta: <b>un préstamo por tu auto usado</b>. Con nosotros, tu auto se
                        convierte en la llave para obtener el financiamiento que necesitas, sin la necesidad de
                        renunciar a él. Acompáñanos a descubrir cómo puedes beneficiarte de esta oportunidad única.
                    </p>

                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">¿Cómo obtener un préstamo con tu coche usado como garantía?</h2>

                    </span>

                    <p class="mb-0">
                        Conseguir un crédito con coche usado con Ibancar es un trámite fácil y rápido. Ibancar toma tu
                        vehículo sólo como garantía, permitiendo así, poder ofrecerte mejores condiciones que las que
                        usualmente encontrarás en préstamos personales convencionales.

                    </p>

                    <span class="bold-text">
                        <br>
                        <span style="font-size: 20px;">¿Te interesa saber cómo hacerlo?
                            Te explicamos el proceso a seguir:</span>
                        <br>
                        <br>
                    </span>
                    <ol>
                        <li>
                            <p class="mb-0 bold-text">
                                Valoración de tu coche:
                            </p>
                            <span>Comienza tu solicitud online, aportando detalles de tu vehículo. Esta información nos
                                permitirá determinar el monto del crédito a ofrecerte.</span>
                        </li>

                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Documentación necesaria:
                            </p>
                            <span>Sólo necesitarás presentar algunos documentos esenciales, como tu identificación
                                oficial, un comprobante de domicilio y la documentación de tu vehículo.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Aprobación rápida:
                            </p>
                            <span>Nuestro equipo evaluará tu solicitud y, cumpliendo con los requisitos, recibirás una
                                respuesta en menos de 24 horas. Una vez aprobada, se programa visita presencial en el
                                domicilio del cliente para revisar el vehículo y recoger la factura.</span>
                        </li>
                        <br>

                        <li>
                            <p class="mb-0 bold-text">
                                Disfruta tu crédito:
                            </p>
                            <span>Tras ser firmado el contrato, el dinero se transferirá directamente a tu cuenta
                                bancaria. Una gran ventaja es que podrás seguir haciendo uso de tu auto mientras dure el
                                préstamo.</span>
                        </li>

                    </ol>

                    <br>
                </div>
            </div>
        </div>
        <div class="section-container regular-text">
            <div class="position-relative  pb-3 pb-md-0 ">
                <div class="text-container">
                    <span class="bold-text">
                        <br>
                        <h2 style="font-size: 20px;">Ventajas de nuestro préstamo por coche usado en México</h2>

                    </span>
                    <p>Obtar por un <b>crédito por tu coche usado</b> con Ibancar te brinda una serie de beneficios
                        clave:</p>
                    <br>
                    <ul>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">No necesitas vender tu automóvil:
                                </span>Consigue el dinero que requieres sin la necesidad de desprenderte de tu vehículo.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Maneja sin límites:
                                </span>Sigue manejando tu auto sin GPS: tendrás total disponibilidad de él sin renting.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Cancela cuando quieras y amortiza desde el primer día:
                                </span>de esta forma podrás liquidar el contrato en cualquier momento.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Proceso claro y sin sorpresas:
                                </span> En Ibancar, la transparencia y la honestidad son esenciales. No te toparás con
                                cláusulas ocultas ni condiciones injustas.

                            </p>
                        </li>
                        <br>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Fondos disponibles rápidamente
                                </span>Entendemos la importancia de tu tiempo, por lo que agilizamos nuestros procesos
                                para que accedas a tu dinero sin demoras.

                            </p>
                        </li>
                        <li>
                            <p class="mb-0">
                                <span class="bold-text">Planes de pago flexibles:
                                </span>Diseñamos los esquemas de pago pensando en tu comodidad, asegurando que la
                                devolución del préstamo se ajuste a tus posibilidades sin representar un problema.

                            </p>
                        </li>
                    </ul>
                    <br>

                </div>

            </div>

        </div>
        <div style="background-color: rgba(255, 165, 0, 0.04); padding: 1.5rem 0rem; margin: 1.5rem 0rem;">
            <div class="ibancar-election">
                <p>¿Por qué elegir Ibancar?</p>
            </div>
            <BeneficiosIbancarCarousel />
        </div>
        <div class="text-container">
            <span class="bold-text">
                <br>
                <h2 style="font-size: 20px;">Requisitos para tu préstamo usando tu auto como aval</h2>
                
            </span>
            <p>Para poder solicitar un <b>crédito por vehículo usado</b> con Ibancar, hay unos puntos clave que
                necesitas conocer:</p>
            <p class="mb-0">
                Poseer un coche a tu nombre, que cumpla con los criterios específicos de antigüedad y estado válido. Te
                invitamos a verificar los detalles actualizados directamente en nuestro sitio web o conectándonos
                directamente, ya que estos pueden variar para asegurar la mejor oferta posible.
            </p>
            <br>
            <p class="mb-0">
                Mantener la documentación del vehículo al día, asegurándose de que esté libre de cualquier restricción
                que pudiera afectar la garantía. Es fundamental que el vehículo no tenga impedimentos legales o
                financieros que limiten su uso.
            </p>
            <br>
            <p>
                En Ibancar, nuestra misión es darte opciones financieras que sean justas y transparentes, que no generen
                ambigüedad. Optar por un crédito con automóvil usado con Ibancar no solo te ofrecerá el efectivo que
                necesitas, sino que también te permitirá seguir disfrutando de tu vehículo sin tener que venderlo.
                Visita nuestra web para ver cómo desde Ibancar, podemos apoyarte a alcanzar tus metas financieras sin
                tener que sacrificar lo que más valoras.
            </p>
        </div>
        <SolicitaloBannerS2 />
    </div>
</template>

<script>
import { useMeta } from 'vue-meta';
import BannerSection from '@/components/Landings/BannerSection.vue';
import BeneficiosIbancarCarousel from '@/components/PrestamoGarantiaAuto/BeneficiosIbancarCarousel.vue';
import SolicitaloBannerS2 from '@/components/Landings/SolicitaloBannerS2.vue';
import PerksComponent from '../components/PerksComponent.vue';


export default {
    name: 'PrestamoPorAutomovilConIbancar',
    components: {
        BannerSection,
        BeneficiosIbancarCarousel,
        SolicitaloBannerS2,
        PerksComponent
    },
    setup() {
        useMeta(
            {
                title: 'Préstamo por tu coche usado en México: Dinero rápido sin venderlo | Ibancar',
                meta: [
                    {
                        name: 'robots',
                        content: 'index'
                    },
                    {
                        name: 'description',
                        content: 'Obtén liquidez inmediata con tu coche usado como garantía en México. Proceso rápido, sin necesidad de vender tu vehículo. Conoce más y solicita tu préstamo hoy.'
                    },
                    {
                        name: 'keywords',
                        content: 'credito coche usado'
                    }
                ],
                link: [
                    // { href: 'https://ibancar.com/prestamos-con-aval-de-coche-sin-cambiar-de-titular', rel: 'alternate', hreflang: 'es-es' },
                    { href: 'https://ibancar.mx/consigue-dinero-por-tu-carro-usado', rel: 'alternate', hreflang: 'es-MX' }
                ]
            }
        )
    },

    data() {
        return {
            ventajas: [
                {
                    image: 'image12.svg',
                    title: 'Ejecutivo personalizado',
                },
                {
                    image: 'solicitud-en-linea.svg',
                    title: 'Proceso simple sin salir de casa',
                },

                {
                    image: 'prestamos-48-hrs.svg',
                    title: 'Hasta $120,000 en 24H',
                },
                {
                    image: 'proceso-simple.svg',
                    title: 'Aceptamos personas en Buró.',
                }

            ]
        }
    }
}
</script>

<style scoped lang="scss">
@import 'bootstrap/scss/_functions.scss';
@import 'bootstrap/scss/_variables.scss';
@import 'bootstrap/scss/_mixins.scss';


.body-container {
    margin-top: 4.5rem;
    min-height: calc(100vh - 115px);
}

@include media-breakpoint-up(md) {
    .body-container {
        margin-top: 8.5rem;
    }
}

.ibancar-election {
    margin: 1rem 2rem 0rem 2rem;

    p {
        color: #004680;
        text-align: center;
        font-family: 'Montserrat-Bold';
        font-size: 1.3rem;
        font-style: normal;
        line-height: normal;
        margin: 1.5rem 0rem;
    }
}

.text-container {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    font-size: 20px;
    text-align: justify;
}

.regular-text {
    font-family: "Montserrat";
    font-size: 20px;
    line-height: 30px;
}

.title {
    font-size: 25px;
    line-height: 30px;
}

.bold-text {
    font-family: "Montserrat-Bold";
}

.text-orange {
    color: $orange;
}

.text-blue {
    color: $blue;
}

.text-black {
    color: $black;
}

.text-underline {
    text-decoration: underline;
}


.btn-solicitar {
    a {
        &:hover {
            text-decoration: none;
        }
    }
}

// Image
.image {
    bottom: 0;
    right: 0;
    width: 30%;
    z-index: -1;
}

@include media-breakpoint-down(md) {
    .text-container {
        width: 90%;
    }

    .regular-text {
        line-height: 1.5;
    }

    ul {
        padding-inline-start: 20px;
    }

    ol {
        padding-inline-start: 20px;
    }
}

@include media-breakpoint-between(md, lg) {
    .regular-text {
        font-size: 16px;
    }

    .title {
        font-size: 28px;
        line-height: 35px;
    }

    .subtitle {
        font-size: 16px;
    }
}

@include media-breakpoint-down(sm) {
    .title {
        font-size: 20px;
        line-height: 25px;
    }

    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }

    .regular-text {
        font-size: 16px !important;
    }

    .section-container {
        margin-top: 1.5rem;
    }
}
</style>